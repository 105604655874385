import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        appId: String,
    };

    static targets = ['toggleButton', 'loadingSpinner', 'content'];

    connect() {
        if (!window.plugSDK.isPlugInitialized) {
            this.hideContent();
            this.initPlugSDK();
            this.initSearchBar();
        } else if (window.plugSDK.isPlugInitialized) {
            this.initSearchBar();
        } else {
            console.log('Plug SDK already initialized');
        }
    }

    disconnect() {
        if (window.plugSDK.isPlugInitialized) {
          try {
            window.plugSDK.shutdown();
          } catch (error) {
            console.error('Error shutting down Plug SDK:', error);
          }
        }
    }


    initPlugSDK() {
        try {
            window.plugSDK.init({
                app_id: this.appIdValue,
                disable_plug_chat_window: true,
            });
        } catch (error) {
            console.error('Error initializing Plug SDK', error);
        }
    }

    initSearchBar() {
        window.plugSDK.onEvent((payload) => {
            if (payload.type === 'ON_PLUG_WIDGET_READY') {
                window.plugSDK.initSearchAgent();
                this.toggleSearchBar();
            }
        });
    }

    toggleSearchBar() {
        if (window.plugSDK && typeof window.plugSDK.toggleSearchAgent === 'function') {
            window.plugSDK.toggleSearchAgent();
            this.hideLoader();
            this.showContent();
        } else {
            console.error('toggleSearchAgent is not available');
        }
    }

    hideLoader() {
        this.loadingSpinnerTarget.style.display = 'none';
    }

    showContent() {
        this.contentTarget.style.display = 'block';
    }

    hideContent() {
        this.contentTarget.style.display = 'none';
    }



    // hideToggleButton() {
    //     if (this.searchBarVisibleValue) {
    //         this.toggleButtonTarget.style.display = 'none';
    //         console.log('HIDE TOGGLE BUTTON');
    //     }
    // }

    // showToggleButton() {
    //     if (!this.searchBarVisibleValue) {
    //         this.toggleButtonTarget.style.display = 'inline-block';
    //         console.log('SHOW TOGGLE BUTTON');
    //     }
    // }
}
